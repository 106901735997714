<template>
  <nav>
    <div class="container">
      <div class="top-bar">
        <div class="row">
          <div class="col-md-4 align">
            <i class="fas fa-phone  mb-0">
              <a class="m-3 telNo" href="tel:+90-542-802-46-30">542-802-46-30</a>
            </i>
          </div>
          <div class="col-md-4 align">
            <router-link :to="{ name: 'Home' }" class="nav-link active">
              <img
                src="../assets/logo-icon/elektirikLogo_.png"
                class="img-fluid"
                width="240px"
                height="120px"
              />
            </router-link>
          </div>
          <div class="col-md-4 align">
            <i class="fas fa-phone  mb-0">
              <a class="m-3 telNo" href="tel:+90-538-239-39-96">538-239-39-96</a>
            </i>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style>
.align {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: -15px;
}

.telNo {
  color: black;
  text-decoration: none;
}
</style>
